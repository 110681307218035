import React, { Component } from 'react'
import './IconButton.scss';

export default class IconButton extends Component {
    render() {
        const {icon: Icon, className, onClick, size, force, borderless} = this.props;

        const classes = className ? className.split(' ') : [];
        classes.unshift('IconButton');
        if (size) classes.push('IconButton--' + size);
        if (force) classes.push('IconButton--force');
        if (borderless) classes.push('IconButton--borderless');
        return (
            <div className={classes.join(' ')} onClick={onClick}>
                <Icon></Icon>
            </div>
        )
    }
}
