import { combineReducers } from "redux";
import { localizeReducer } from "react-localize-redux";
import itemStateReducer from './itemState';
import docStoreReducer from './docState';
import resStateReducer from './resourcesState';

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    localize: localizeReducer,
    itemState: itemStateReducer,
    docState: docStoreReducer,
    resState: resStateReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
