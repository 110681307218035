import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import MetaTags from "react-meta-tags";
import { titleFunction } from "helper/pageInfo";

const AppliedRoute = ({
  component: C,
  props: {meta, ...cProps},
  translate,
  title,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => [
        <MetaTags key={meta}>
          <title>{titleFunction(translate, translate(`${meta}.title`))}</title>
          <meta property='og:description' content={translate(`${meta}.description`)}/>
          <meta property='og:type' content='website'/>
        </MetaTags>,
        <C key={meta + "-comp"} {...props} {...cProps} />,
      ]}
    />
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(AppliedRoute);
