import BemClass from "@upsales/bemclass";
import Text from "components/Text";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import download from "assets/download.svg";
import wsdl from "assets/wsdl.svg";
import xsd from "assets/xsd.svg";
import "./Workflow.scss";

const flow = [
    {
        icon: wsdl,
        text: "overview.workflow.step1",
    },
    {
        icon: xsd,
        text: "overview.workflow.step2",
    },
    {
        icon: download,
        text: "overview.workflow.step3",
    },
];

class Workflow extends React.Component {
    render() {
        const { translate, className } = this.props;
        const bem = new BemClass("Workflow", className);

        return (
            <div className={bem.b()}>
                {flow.map(({ icon, text }, i) => (
                    <div className={bem.elem("step")}>
                        <img src={icon} alt=''></img>
                        <Text center>
                            {/* <span className={bem.elem("step-index")}>{i + 1}</span> */}
                            {translate(text)}
                        </Text>
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Workflow);
