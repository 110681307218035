
// ------------------------------------
// Constants
// ------------------------------------
const SET_VALUE = "[RES STATE] Set value";
const CLEAR_STATE = "[RES STATE] Clear state";

// ------------------------------------
// Actions
// ------------------------------------
export function setResources(res) {
  return dispatch => {
    dispatch({
      type: SET_VALUE,
      payload: res
    });
  };
}

export function clearState() {
  return {
    type: CLEAR_STATE,
    payload: baseState
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_VALUE]: (state, action) => action.payload,
  [CLEAR_STATE]: (state, action) => action.payload
};

// ------------------------------------
// Reducer
// ------------------------------------
const baseState = {};
export default function docStateReducer(state = baseState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
