import BemClass from "@upsales/bemclass";
import Modal from "components/Modal";
import Text from "components/Text";
import Title from "components/Title";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { pathDonate } from "routes";
import './SuccessModal.scss';


const SuccessModal = (props) => {
    const bem = new BemClass("SuccessModal");

    const { isOpen, onClose, translate } = props;

    return (
        <Modal className={bem.b()} dialog isOpen={isOpen} onClose={onClose}>
            <Title center>{translate('gen.success')}</Title>
            <Text center>{translate('gen.success.text')}</Text>
            <Text center><Link to={pathDonate}>{translate('gen.success.donate')}</Link></Text>
        </Modal>
    );
};
const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});
export default connect(mapStateToProps)(SuccessModal);
