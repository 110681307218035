import AddIcon from "@material-ui/icons/Add";
import BemClass from "@upsales/bemclass";
import ListAdd from "components/Form/ListAdd/ListAdd";
import Title from "components/Title";
import React, { Component } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import File from "./File";
import "./FileInput.scss";
import upload from './upload.svg';


const formUploadID = "videoToUpload";

class FileInput extends Component {

  state = {drag: false}

  constructor(props) {
    super(props);
    this.handleFiles = this.handleFiles.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onChange(index, newValue) {
    const { field, value, onChange } = this.props;
    if (newValue) value[index] = newValue;
    else value.splice(index, 1);
    onChange(field, value);
  }

  onSelected() {
    const elem = document.getElementById(formUploadID);
    if (!(elem && elem.files && elem.files.length)) return;
    this.handleFiles(elem.files);
  }

  handleFiles(files) {
    const value = this.props.value;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const res = {
        name: file.name,
        type: file.type,
        size: file.size
      };
      if (!res.type) {
        if (res.name.endsWith(".xsd") || res.name.endsWith(".wsdl"))
          res.type = "application/xml";
      }
      console.log(res.type);
      res.err = [];
      if (res.size > 2000000) res.err.push("file.err.size");
      if (res.type !== 'application/xml') res.err.push("file.err.type");
      if (!res.err.length) res.file = file;
      value.push(res);
    }

    this.onChange(this.props.field, value);
  }

  onDragOver(ev) {
    ev.preventDefault();
    ev.forceAllow = true;
  }

  onDrop(ev) {
    ev.preventDefault();
    this.setState({drag: false});
    if (!ev?.dataTransfer?.files?.length) return;

    this.handleFiles(ev.dataTransfer.files);
  }

  render() {
    const { value, className } = this.props;
    const bem = new BemClass("FileInput", className);
    const { drag } = this.state;
    return (
      <div className={bem.b()}>
        <div className={bem.elem('drop-info') + " noselect"}>
          <img src={upload} alt='Upload'></img>
          <Title center>{'Drop files here'}</Title>
        </div>
        <div className={bem.elem("wrapper").mod({drag: drag}).b()} onDragOver={this.onDragOver} onDrop={this.onDrop} >
          {value
            ? value.map((v, i) => (
                <File
                  key={"ns" + i}
                  value={v}
                  index={i}
                  onChange={v => this.onChange(i, v)}
                ></File>
              ))
            : null}
          <ListAdd
            size='md'
            onClick={() => {
              const elem = document.getElementById(formUploadID);
              elem.value = null;
              elem.files = null;
              elem.click();
            }}
            icon={AddIcon}
          />
          <div className={bem.elem("form")}>
            <form
              style={{ height: "0px" }}
            >
              <input
                style={{ height: "0px" }}
                type='file'
                accept='.xsd,.wsdl,.xml'
                name='fileToUpload'
                multiple
                id={formUploadID}
                onChange={() => this.onSelected()}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
  value: props.value || []
});

export default connect(mapStateToProps)(FileInput);
