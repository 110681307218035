import axios from "axios";
import { API_PATH } from "./index";

//export const gen = (formdata) => axios.post(`http://192.168.2.52:7509/servlet`, formdata).then(res => res.data);
export const gen = (formdata) => axios.post(API_PATH + `/gen`, formdata).then(res => res.data).then(parseResult)

const parseResult = (({status, errlog, ...data}) => {

    return {
        ...data,
        errlog: errlog ? errlog.split("\n") : [],
        status: parseInt(status)
    }
})