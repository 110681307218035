import BemClass from "@upsales/bemclass";
import copy from "assets/copy.png";
import WseButton from "components/Button/WseButton";
import GenButton from "components/GenButton/GenButton";
import Input, { getShowInput } from "components/Input";
import Modal from "components/Modal";
import Text from "components/Text";
import Title from "components/Title";
import WsdlTemplateModal from "components/WsdlTemplateModal/WsdlTemplateModal";
import { inputType } from "helper/genForm";
import { selectText } from "helper/util";
import React, { useEffect, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { modules } from "routes";
import { setResult, setValue } from "store/itemState";
import "./Gen.scss";
import SuccessModal from "./SuccessModal/SuccessModal";

export const resultUrl = (result) => `/api/gen?id=${result}`;

const {
  text: { projectName, javaPackage, dartProjectName},
  tabselect: { language },
  checkbox: { serverStubs, UnrealEngine, ignoreReplace },
  namespace: { namespace },
  fileInput: { fileInput },
} = inputType;

const gen_form = "gen";
const Gen = (props) => {
  const { translate, setValue, setResult, genResult = {}, showInput } = props;
  const compact = !useMediaQuery({ minWidth: 1000 });
  const bem = new BemClass("Gen", compact ? "Gen--compact" : undefined);

  const [drag, setDrag] = useState(false);
  const [showErrLog, setShowErrLog] = useState(false);
  const [showGenTemplate, setShowGenTemplate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { status, errlog = [], result } = genResult;

  useEffect(() => {
    if (errlog.length) {
      setShowErrLog(true);
    }
  }, [status, errlog]);

  useEffect(() => {
    if (result) {
      window.open(resultUrl(result), "_self");
      setShowSuccess(true);
    }
  }, [result]);

  const module_project = (
    <div className={bem.elem("module").mod("project")}>
      <Title size="md">{translate("gen.project")}</Title>
      <Input {...language} form={gen_form}></Input>
      <Input {...projectName} form={gen_form}></Input>
      <Input {...javaPackage} form={gen_form}></Input>
      <Input {...dartProjectName} form={gen_form}></Input>
      <Input {...UnrealEngine} form={gen_form}></Input>
    </div>
  );

  const options = [serverStubs, ignoreReplace]
    .map((v) => ({ ...v, form: gen_form }))
    .filter((v) => showInput(v))
    .map((v) => <Input {...v} />);

  const module_options = options.length ? (
    <div className={bem.elem("module").mod("options")}>
      <Title size="md">{translate("gen.options")}</Title>
      {options}
    </div>
  ) : null;

  const module_namespace = (
    <div className={bem.elem("module").mod("namespace")}>
      <Title size="md">{translate("gen.namespace")}</Title>
      <Input {...namespace} form={gen_form}></Input>
    </div>
  );
  const module_input = (
    <div className={bem.elem("module").mod("input")}>
      <div className={bem.elem("module").elem("header")}>
        <Title size="md">{translate("gen.input")}</Title>
        <div
          className={bem.elem("remove-all")}
          onClick={() => {
            setValue(gen_form, fileInput.field, []);
          }}
        >
          <span>{translate("gen.input.removeAll")}</span>
        </div>
      </div>
      <Input {...fileInput} form={gen_form}></Input>

      <WseButton
        className={bem.elem("gen-template").b()}
        onClick={() => setShowGenTemplate(true)}
      >
        New Template
      </WseButton>
    </div>
  );
  const module_build = (
    <div className={bem.elem("module").mod("build")}>
      <GenButton {...modules.gen} active />
    </div>
  );

  const events = {
    onDragOver: (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (ev.forceAllow) {
        ev.forceAllow = undefined;
        ev.dataTransfer.dropEffect = "move";
      } else {
        ev.dataTransfer.dropEffect = "none";
      }

      if (!drag) setDrag(true);
      return false;
    },
    onDragLeave: (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (drag) setDrag(false);
    },
    onDrop: (ev) => {
      setDrag(false);
      // ev.preventDefault();
      return false;
    },
    onDragEnter: (ev) => {
      return false;
    },
    onDragStart: (ev) => {
      return false;
    },
    onMouseDown: (ev) => {
      return false;
    },
  };

  return (
    <div className={bem.mod({ drag: drag }).b()} {...events} draggable={false}>
      <div className={bem.elem("beta")}>
        <Text size="md" center>
          {translate("gen.beta")} <a href="mailto:info@wse.app">info@wse.app</a>
        </Text>
      </div>
      <div className={bem.elem("section-wrapper")}>
        <div className={bem.elem("section").mod("left").b()}>
          {module_project}
          {module_options}
          {module_namespace}
        </div>
        <div className={bem.elem("section").mod("right").b()}>
          {module_input}
          {module_build}
        </div>
      </div>
      <Modal
        className={bem.elem("modal")}
        dialog
        isOpen={showErrLog}
        onClose={() => {
          setShowErrLog(false);
          setResult();
        }}
      >
        <div className={bem.elem("err-log").b()} id={"errlog"}>
          <div className={bem.elem("err-title").b()}>
            <Title size="sm">{translate("gen.error.title")}</Title>
            <div
              className={bem.elem("err-select-all").b() + " noselect"}
              onClick={() => {
                selectText("errlog");
                document.execCommand("copy");
              }}
            >
              <div>{translate("default.copyAll")}</div>
              <img alt="Select all" src={copy}></img>
            </div>
          </div>
          {errlog.map((msg, i) => {
            const id = "gen-error-i" + i;
            return (
              <div
                key={id}
                className={bem.elem("err").b()}
                id={id}
                onClick={() => selectText(id)}
              >
                {msg}
              </div>
            );
          })}
        </div>
      </Modal>
      <WsdlTemplateModal
        isOpen={showGenTemplate}
        onClose={() => setShowGenTemplate(false)}
      />
      <SuccessModal
        isOpen={showSuccess}
        onClose={() => {
          setShowSuccess(false);
          setResult();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
  genResult: state.itemState.result,
  showInput: (props) => getShowInput(state)(props.showIf, props.form),
});

const mapDispatchToProps = {
  setValue,
  setResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gen);
