import BemClass from "@upsales/bemclass";
import Openable from "components/Openable/Openable";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import HelpTOC from "../HelpTOC/HelpTOC";
import "./HelpMenuBar.scss";

const HelpMenuBar = (props) => {
    const desktop = useMediaQuery({ minWidth: 800 });
    document.body.style.backgroundColor = "white";

    const { page } = props;

    const bem = new BemClass("HelpMenuBar");

    if (desktop) {
        document.body.style.overflowY = null;
        return <div className={bem.elem("Menu").b()}>
            <HelpTOC page={page} />
        </div>;
    }

    return (
        <Openable>
            {(isOpen, setOpen) => {
                document.body.style.overflowY = isOpen ? "hidden" : null;
                return [
                    <div className={bem.elem("TopBar").b()}>
                        <button
                            className={"noselect " + bem.elem("burger").b()}
                            onClick={() => setOpen(true)}
                        >
                            <div />
                            <div />
                            <div />
                        </button>
                    </div>,
                    <div className={bem.elem("SideMenu").mod({ isOpen }).b()}>
                        <HelpTOC page={page} />
                    </div>,
                    <div
                        className={bem.elem("Overlay").mod({ isOpen }).b()}
                        onClick={() => setOpen(false)}
                    ></div>,
                ];
            }}
        </Openable>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
    path: props.location?.pathname,
});

export default connect(mapStateToProps)(HelpMenuBar);
