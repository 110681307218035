import BemClass from "@upsales/bemclass";
import { getDocs, getOverview } from "helper/docs";
import mermaid from "mermaid";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, withRouter } from "react-router-dom";
import "./help.scss";
import HelpTopBar from "./HelpTopBar/HelpMenuBar";


const getTOC = (content) => {
  if (!content) return;

  console.log("getTOC", typeof(content));
  // console.log(content);

  const toc = [];

  const bem = new BemClass("DocumentTOC");

  const types = {
    "h1": ({children, ...rest}) => <h1 {...rest} >{'Table of Contents'}</h1>,
    "h2": ({children, ...rest}) => <a {...rest}><h2>{children}</h2></a>, 
    "h3": ({children, ...rest}) => <a {...rest}><h3>{children}</h3></a>
  }

  Object.values(content).forEach(section => {
    if (Object.keys(types).findIndex(v => v === section.type) < 0) return;
    console.log(section);

    if (!section.props?.id) return;

    const Render = types[section.type];

    // toc.push(<a className={bem.elem("Section").b()} href={`#${section.props.id}`}><Render>{section.props.children}</Render></a>)
    toc.push(<Render className={bem.elem("Section").b()} href={`#${section.props.id}`}>{section.props.children}</Render>)
  });

  if (toc.length <= 2) return undefined;

  return (<div className={bem.b()}>
    {toc}
    </div>)
}

const Help = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 800 });
  const bem = new BemClass("Help");

  const {
    getDocs,
    getOverview,
    location: { pathname },
    match: { path },
    docState,
  } = props;

  const page = pathname.substring(path.length + 1) || "start";
  const content = docState[page];

  const hideTOC = page === "start";

  useEffect(() => {
    if (page) {
      getDocs(page);
    }
  }, [page, getDocs]);

  useEffect(() => {
    getOverview();
  }, [getOverview]);

  useEffect(() => {
    var config = {
      startOnLoad: true,
      htmlLabels: true,
    };
    // console.log("initialize");
    // console.log(mermaid);
    mermaid.initialize(config);
  }, []);

  const toc = getTOC(content);

  return (
    <div className={bem.mod({ isDesktop, hideTOC }).b()}>
      {!isDesktop ? <HelpTopBar page={page} /> : null}
      <div className={bem.elem("Grid").b()}>
        {isDesktop ? (
          <div className={bem.elem("Left").b()}>
            <HelpTopBar page={page} />
          </div>
        ) : null}

        <div className={bem.elem("Right").b()}>
          {toc}
          {content ? (
            <div
              ref={(r) => {
                if (r != null) mermaid.init();
              }}
              className={bem.elem("Document").b()}
            >
              {content}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  docState: state.docState,
});

const mapDispatchToProps = {
  getDocs,
  getOverview,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));
