import React from "react";

import "./Donate.scss";
import BemClass from "@upsales/bemclass";
import DonateContent from "./Content/DonateContent";

const Donate = (props) => {
    const bem = new BemClass("Donate");

    return <div className={bem.b()}>
        <div className={bem.elem('frame').b()}>
            <DonateContent />
        </div>
    </div>;
};

export default Donate;
