import React from 'react';
import PropTypes from 'prop-types';

import TextInput, { transformValue } from 'components/TextInput/TextInput';

import './Text.scss';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  children: PropTypes.object,
  translate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.func,
  parse:  PropTypes.func,
  template: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  valid: PropTypes.array
};

export const defaultProps = {
  value: ''
};

class Text extends React.PureComponent {
  onChange (newValue) {
    const { value, field, onChange, parse } = this.props;
    if (Array.isArray(field)) {
      const [, ...restValues] = value;
      const valueArr = [newValue, ...restValues];
      onChange(field[0], field.reduce((out, f, i) => {
        out[f] = transformValue(valueArr[i], parse);
        return out;
      }, {}));
    } else {
      onChange(field, transformValue(newValue, parse));
    }
  }

  render () {
    const { className, children, ...props } = this.props;
    return (
      <div className={`Text ${className || ''}`}>
        <TextInput
          {...props}
          onChange={e => this.onChange(e.target.value)}
        />
        {children && <div className='checkbox-container'>
          {children}
        </div>}
      </div>
    );
  }
}

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
export default Text;
