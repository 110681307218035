export const mergeForm = (state, { form, field, value }) => {
  const values = (() => {
    if (value === null) {
      delete state[form][field];
      return Object.assign({}, state[form]);
    } else if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      !(value instanceof File)
    ) {
      return Object.assign({}, state[form], { ...value });
    } else {
      return Object.assign({}, state[form], { [field]: value });
    }
  })();
  return Object.assign({}, state, { [form]: values });
};
