import BemClass from "@upsales/bemclass";
import DonateButton from "components/DonateButton/DonateButton";
import Logo from "components/Logo/Logo";
import Text from "components/Text";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { OverviewTitle } from "../Overview";
import "./About.scss";

const mapStateToProps = (state, props) => ({
    t: getTranslate(state.localize),
});

export default connect(mapStateToProps)(({t}) => {
    const bem = new BemClass("About");
    return <div className={bem.b()}>
        <Logo auto/>
        <Text center>{t("overview.about.what", null, { renderInnerHtml: true })}</Text>
        <div className={bem.elem("links").b()}>
            <a href="#runtimes"><div>Runtimes</div></a>
            <a href="#shttp"><div>SHttp</div></a>
            <a href="#pricing"><div>Pricing</div></a>
        </div>
    </div>
});

const Card = connect(mapStateToProps)(({ name, t, children, notitle, id }) => {
    const bem = new BemClass("About__card");
    return (
        <div className={bem.b()} id={id}>
            {notitle ? null : (<OverviewTitle>
                {t(`${name}.title`)}
            </OverviewTitle>)}
            <Text center>{t(name, null, { renderInnerHtml: true })}</Text>
            {children}
        </div>
    );
});

export const WhatIs = () => <Card name='overview.about.what' />;
export const Shttp = () => <Card id="shttp" name='overview.shttp'>
    {/* <WseButton rotate>Read More</WseButton> */}
</Card>;
export const Donate = () => (
    <Card id="pricing" name='overview.about.pricing'>
        <DonateButton />
    </Card>
);
