import BemClass from "@upsales/bemclass";
import React from "react";
import { Link } from "react-router-dom";
import "./WseButton.scss";

const WseButton = (props) => {
    const { children, className, onClick, id, link, rotate } = props;

    const bem = new BemClass("WseButton");

    const content = (
        <div {...{ onClick, id }} className={`${className} ${bem.elem("layout").mod({rotate}).b()}`}>
            <div className={bem.elem("border").b()}/>
            <div className={bem.b()}>
                {children}
            </div>
        </div>
    );

    if (link) {
        return <Link className={bem.elem('link').b()} to={link}>{content}</Link>
    } else {
        return content;
    }
};

export default WseButton;
