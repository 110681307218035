export const valueIs = (value, comp) => value && value.indexOf(comp) >= 0;

export const parseNumber = (val) => {
  if (val === "") {
    return "";
  } else {
    return (
      parseInt(
        val.replace(new RegExp(" ", "g"), "").replace(/[^0-9]/g, ""),
        10
      ) || ""
    );
  }
};

const advanced = () => ({ viewAdvanced: (v) => !!v });

const oneOf = (options) => (v) => {
  return options.indexOf(v) >= 0;
};

const LANG_JAVA = "java";
const LANG_C = "c";
const LANG_OBJC = "objc";
const LANG_DART = "dart";

export const inputType = {
  text: {
    projectName: {
      name: "gen.project.name",
      field: "projectName",
      type: "text",
      autoComplete: "off",
      showIf: { language: oneOf([LANG_C, LANG_OBJC]) },
    },
    dartProjectName: {
      name: "gen.project.dartname",
      field: "projectName",
      type: "text",
      autoComplete: "off",
      parse: (v) => {
        v = v.toLowerCase();
        v = v.replace(/^[0-9]/, " ");
        v = v.replaceAll(/[^0-9a-zA-Z]+/, " ");
        v = v.replaceAll(" ", "_");

        v = v.startsWith('_') ? v.substring(1) : v;

        return v;
      },
      showIf: { language: oneOf([LANG_DART]) },
    },
    javaPackage: {
      name: "gen.java.package",
      field: "javaPackage",
      type: "text",
      disableReset: true,
      showIf: { language: oneOf([LANG_JAVA]) },
      autoComplete: "off",
    },
    templateName: {
      name: "gen.template.service",
      field: "service",
      type: "text",
      autoComplete: "off",
      disableReset: true,
    },
    templateHost: {
      name: "gen.template.host",
      field: "host",
      type: "text",
      autoComplete: "off",
      disableReset: true,
    },
    templatePort: {
      name: "gen.template.port",
      field: "port",
      type: "number",
      autoComplete: "off",
      disableReset: true,
      parse: parseNumber,
    },
    templateSoapAction: {
      name: "gen.template.soapAction",
      field: "soapAction",
      type: "text",
      autoComplete: "off",
      disableReset: true,
      showIf: advanced(),
    },
    templatePath: {
      name: "gen.template.path",
      field: "path",
      type: "text",
      autoComplete: "off",
      disableReset: true,
      showIf: advanced(),
      parse: (v) => {
        v = v.replace("//", "/");
        return v.startsWith("/") ? v : "/" + v;
      },
    },
  },
  namespace: {
    namespace: {
      name: "gen.namespace",
      field: "namespace",
      type: "namespace",
    },
  },
  fileInput: {
    fileInput: {
      name: "gen.input",
      field: "fileInput",
      type: "fileInput",
    },
  },
  tabselect: {
    language: {
      name: "gen.project.language",
      field: "language",
      type: "tabselect",
      options: [
        { name: (t) => t("default.java"), value: LANG_JAVA },
        { name: (t) => t("default.c"), value: LANG_C },
        { name: (t) => t("default.objc"), value: LANG_OBJC },
        { name: (t) => t("default.dart"), value: LANG_DART },
      ],
    },
    protocol: {
      name: "gen.template.protocol",
      field: "protocol",
      type: "tabselect",
      options: [
        { name: (t) => t("gen.template.http"), value: "http" },
        { name: (t) => t("gen.template.https"), value: "https" },
        { name: (t) => t("gen.template.shttp"), value: "shttp" },
      ],
    },
  },
  checkbox: {
    allStrings: {
      name: "gen.options.allStrings",
      field: "allStrings",
      type: "checkbox",
    },
    emptyStrings: {
      name: "gen.options.emptyStrings",
      field: "emptyStrings",
      type: "checkbox",
    },
    serverStubs: {
      name: "gen.options.serverStubs",
      field: "serverStubs",
      type: "checkbox",
      showIf: { language: oneOf([LANG_C, LANG_JAVA]) },
    },
    genDocs: {
      name: "gen.options.docs",
      field: "genDocs",
      type: "checkbox",
    },
    UnrealEngine: {
      name: "gen.c.ue4",
      field: "genUE4",
      type: "checkbox",
      showIf: { language: oneOf([LANG_C]) },
    },
    viewAdvanced: {
      name: "gen.template.viewAdvanced",
      field: "viewAdvanced",
      type: "checkbox",
    },
    ignoreReplace: {
      name: "gen.ignoreReplace",
      field: "ignoreReplace",
      showIf: { language: oneOf([LANG_JAVA]) },
      type: "checkbox",
    },
  },
};
