
export const API_PATH = '/api';
export const IMG_PATH = '/img';

export const paypalDonateLink = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7D823PF9DRS22";


export * from './docs';
export * from './downloads';
export * from './gen';
export * from './res';