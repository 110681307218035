import BemClass from "@upsales/bemclass";
import DonateButton from "components/DonateButton/DonateButton";
import Text from "components/Text";
import Title from "components/Title";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import "./DonateContent.scss";


const DonateContent = (props) => {
    const bem = new BemClass("DonateContent");

    const { translate } = props;

    return (
        <div className={bem.b()}>
            <Title center>{translate("donate.prep.considering")}</Title>
            <Text center>{translate("donate.prep.description")}</Text>
            <DonateButton />
        </div>
    );
};
const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});
export default connect(mapStateToProps)(DonateContent);
