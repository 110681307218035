import BemClass from "@upsales/bemclass";
import React from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";
import Links from "./Links";
import "./Links.scss";
import "./Resources.scss";
import "./Tutorials.scss";

const Resources = (props) => {
  const desktop = useMediaQuery({ minWidth: 800 });
  // const [tutorialTab, setTutorialTab] = useState(false);

  const bem = new BemClass("Resources");

  if (desktop) {
    return (
      <div className={bem.mod("Desktop").b()}>
        <Links />
      </div>
    );
  }

  const content = /*tutorialTab ? <Tutorials /> :*/ <Links />;

  return <div className={bem.mod("Mobile")}>{content}</div>;
};

export default withRouter(connect()(Resources));
