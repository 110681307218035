import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";

import text from "./Text/Text";
import checkbox from "./Checkbox/Checkbox";
import tabselect from "./TabSelect/TabSelect";
import namespace from "./Namespace/Namespace";
import fileInput from "./FileInput/FileInput";
import select from "./Select/Select";

import { setValue, resetValue } from "store/itemState";

import "./Input.scss";

const inputMap = {
  text,
  number: text,
  password: text,
  textarea: text,
  checkbox,
  tabselect,
  namespace,
  fileInput,
  select,
};

const getFieldData = (itemState, { form, field }) => {
  if (Array.isArray(field)) {
    return field.map((f) => _get(itemState, [form, f]));
  } else {
    return _get(itemState, [form, field]);
  }
};

export const getShowInput =
  (state) =>
  (showIf = {}, form) =>
    Object.keys(showIf).every((field) =>
      showIf[field](_get(state.itemState, [form, field]))
    );

const Inputs = (props) => {
  const {
    type,
    className = "",
    setValue,
    onChange = () => {},
    showInput,
    form,
    isFormValid,
    isValid,
  } = props;

  const Input = inputMap[type];
  const classes = className.split(" ");
  classes.push("Inputs");
  if (isValid === false && isFormValid === false) {
    classes.push("Inputs--invalid");
  }

  // console.log(props.name, showInput);

  if (!showInput) {
    return null;
  } else if (Input) {
    return (
      <Input
        {...props}
        onChange={(field, val) => {
          /*allValid(valid, val).then(isValid => {
              setValid(form, field, isValid);
            });*/
          setValue(form, field, val);
          if (onChange) onChange(val);
        }}
        className={classes.join(" ")}
      />
    );
  }
  return <p className={classes.join(" ")}>NOT IMPLEMENTED</p>;
};

const mapStateToProps = (state, props) => ({
  value: getFieldData(state.itemState, props),
  showInput: getShowInput(state)(props.showIf, props.form),
  /*isValid: _get(
    state.validState,
    [props.form, Array.isArray(props.field) ? props.field[0] : props.field],
    true
  ),*/
  state,
  //isFormValid: state.itemStep.valid[props.form]
});

const mapDispatchToProps = {
  resetValue,
  setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inputs);
