import BemClass from '@upsales/bemclass';
import FormCheckbox from 'components/Form/FormCheckbox/FormCheckbox';
import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './Checkbox.scss';

export const defaultProps = {
  value: '',
  options: {}
};

class Checkbox extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value || this.props.form !== nextProps.form;
  }
  onChange = () => {
    const { field, onChange, value, options } = this.props;
    if (options.value) {
      onChange(field, value === options.value ? undefined : options.value);
    } else {
      onChange(field, value ? 0 : 1);
    }
  };

  render() {
    const { className, infoName, field, options, reqEnabled, ...props } = this.props;
    const { name, value, translate } = props;

    const bem = new BemClass('Checkbox', className);
    return (
      <div className={bem.mod({ required: reqEnabled, 'required-open': reqEnabled && value }).b()}>
        <div className={bem.elem('wrapper').b()}>
          <FormCheckbox value={value} name={name} translate={translate} onChange={this.onChange} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  translate: getTranslate(state.localize)
});

Checkbox.defaultProps = defaultProps;
export default connect(mapStateToProps)(Checkbox);
