import React, { Component } from "react";
import BemClass from "@upsales/bemclass";
import FormTitle from "components/Form/FormTitle/FormTitle";

import "./TabSelect.scss";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

class TabSelect extends Component {
  render() {
    const { value, name, options, translate, className, field, onChange} = this.props;
    
    const bem = new BemClass("TabSelect", className);
    return (
      <div className={bem.b()}>
        <MediaQuery minWidth={400}>
        {name && (
          <FormTitle>
            {translate(name)}
            {/*getRequired(valid) && <span className='input-required'>*</span>*/}
          </FormTitle>
        )}
        </MediaQuery>
        { options.map((option => {
          return (<div key={option.value} className={bem.elem('option').mod({selected: option.value === value}).b()} onClick={() => onChange(field, option.value)}>
          {option.name(translate)}
        </div>)
        }))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize)
});

export default connect(mapStateToProps)(TabSelect);
