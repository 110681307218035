import BemClass from "@upsales/bemclass";
import Openable from "components/Openable/Openable";
import { useScrollPosition } from "helper/useScrollPosition";
import React, { useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { modules } from "routes";
import TopBarButton from "components/TopBarButton/TopBarButton";
import "./DesktopBar.scss";
import "./PhoneBar.scss";
import wselogo from "assets/wse_icon.svg";
import GenButton from "components/GenButton/GenButton";

const PhoneBar = (props) => {
    const bem = new BemClass("PhoneBar");

    const { path } = props;

    const [scrollTop, setTop] = useState(true);

    useScrollPosition(({ prevPos, currPos }) => {
        setTop(currPos.y >= -5);
    });
    // const scrollTrigger = useScrollTrigger();

    return (
        <div className={"TopBar"}>
            <div className={"TopBar__filler"} />
            <Openable>
                {(isOpen, setOpen) => {
                    document.body.style.overflowY = isOpen ? "hidden" : null;
                    return [
                        <div
                            className={bem
                                .mod({ isOpen, scrollTop /*scrollTrigger*/ })
                                .b()}
                        >
                            <button
                                className={"noselect " + bem.elem("burger").b()}
                                onClick={() => setOpen(true)}
                            >
                                <div />
                                <div />
                                <div />
                            </button>
                        </div>,
                        <div
                            className={bem.elem("Overlay").mod({ isOpen }).b()}
                            onClick={() => setOpen(false)}
                        ></div>,
                        <div
                            className={bem.elem("Expanded").mod({ isOpen }).b()}
                        >
                            <div className={bem.elem("top").b()}>
                                <img
                                    className={bem.elem("logo")}
                                    src={wselogo}
                                    alt=''
                                />
                                <TopBarButton
                                    {...modules.gen}
                                    className={"TopBar__GenButton"}
                                />
                            </div>
                            <div className={bem.elem("divider")} />
                            <div className={bem.elem("links")}>
                                <div className={bem.elem("left")}>
                                    <TopBarButton
                                        {...modules.overview}
                                        active={path === modules.overview.path}
                                        onClick={(e) => {}}
                                    />
                                    <TopBarButton
                                        {...modules.resources}
                                        active={path === modules.resources.path}
                                        onClick={(e) => {}}
                                    />
                                    <TopBarButton
                                        {...modules.help}
                                        active={path === modules.help.path}
                                        onClick={(e) => {}}
                                    />
                                </div>
                            </div>
                        </div>,
                    ];
                }}
            </Openable>
        </div>
    );
};

const DesktopBar = (props) => {
    const bem = new BemClass("DesktopBar");

    const { path } = props;

    document.body.style.overflowY = null;

    return (
        <div className={"TopBar"}>
            <div className={"TopBar__filler"} />
            <div className={bem.b()}>
                <div className={bem.elem("center").b()}>
                    <div className={bem.elem("ClusterLeft").b()}>
                        <Link to='/' className={bem.elem("logo")}>
                            <img src={wselogo} alt='' />
                        </Link>
                        {/* <TopBarButton
                            {...modules.overview}
                            active={path === modules.overview.path}
                            onClick={(e) => {}}
                        /> */}
                        <TopBarButton
                            {...modules.resources}
                            active={path === modules.resources.path}
                            onClick={(e) => {}}
                        />
                        <TopBarButton
                            {...modules.help}
                            active={path === modules.help.path}
                            onClick={(e) => {}}
                        />
                    </div>
                    <div className={bem.elem("ClusterRight").b()}>
                        <GenButton {...modules.gen} active={path === modules.gen.path}/>
                        {/* <TopBarButton
                            {...modules.gen}
                            className={"TopBar__GenButton"}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TopBar = (props) => {
    document.body.style.backgroundColor = "#303030";
    const desktop = useMediaQuery({ minWidth: 800 });

    if (desktop) return <DesktopBar {...props} />;
    return <PhoneBar {...props} />;
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
    path: props.location?.pathname,
});

export default connect(mapStateToProps)(TopBar);
