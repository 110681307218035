import { mergeForm } from "./stateHelper";

// ------------------------------------
// Constants
// ------------------------------------
const SET_VALUE = "[GEN STATE] Set value";
const SET_FORM = "[GEN STATE] Set form";
const RESET_VALUE = "[GEN STATE] Reset value";
const CLEAR_STATE = "[GEN STATE] Clear state";

// ------------------------------------
// Actions
// ------------------------------------
export function setValue(form, field, value) {
  return dispatch => {
    dispatch({
      type: SET_VALUE,
      payload: {
        form,
        field,
        value
      }
    });
  };
}

export function resetValue(form, field) {
  return (dispatch, getState) => {
    const state = getState().itemState;
    const formValue = state[form];
    if (formValue) {
      delete formValue[field];
      return {
        type: RESET_VALUE,
        form: { [form]: formValue }
      };
    }
  };
}

export function setResult(result) {
  return (dispatch, getState) => {
    dispatch( {
      type: SET_FORM,
      payload: {
        result
      }
    });
  }
}

export function clearState(type) {
  return {
    type: CLEAR_STATE,
    payload: baseState
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_VALUE]: (state, action) => mergeForm(state, action.payload),
  [SET_FORM]: (state, action) => ({ ...state, ...action.payload }),
  [RESET_VALUE]: (state, action) => ({ ...state, ...action.form }),
  [CLEAR_STATE]: (state, action) => action.payload
};

// ------------------------------------
// Reducer
// ------------------------------------
const baseState = {
  gen: {
    language: "java",
    javaPackage: "wse.generated",
    projectName: "wse",
    namespace: [
      {ns: 'https://wse.app/ns/wse', prefix: 'wse'},
      {ns: '', prefix: ''},
      {ns: '', prefix: ''}
    ],
  },
  template: {
    service: 'Template',
    protocol: 'http',
    host: 'localhost',
    port: '1234'
  }
};
export default function itemStateReducer(state = baseState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
