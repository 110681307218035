export const parseGenForm = ({ namespace = [], fileInput = [], ...form }) => ({
  ...form,
  namespace: namespace.filter(v => v.ns && v.prefix),
  fileInput: fileInput.filter(v => !v.err.length).map(({err, ...rest}) => rest)
});

export const validGenForm = ({
  language,
  javaPackage,
  fileInput,
  projectName
} = {}) => {

  const err = [];
  if (!language) {
    err.push('gen.error.language');
  }
  if (language === "java") {
    console.log("lang");
    if (!javaPackage) {
      err.push('gen.error.javaPackage');
    }else if(!javaPackage.match("(?:\\w+|\\w+\\.\\w+)")) {
      err.push('gen.error.javaPackage');
    }else{
      console.log("matches");
    }
  }

  if (language === "c" || language === "objc") {
    if (!projectName) err.push('gen.error.pluginName');
  }

  if (language === "dart") {
    if (!projectName) err.push('gen.error.projectName');
  }
  
  if (!fileInput.length) {
    err.push('gen.error.noInput');
  }
  return err;
};

// Not used
export const makeGenData = ({fileInput, ...rest}) => {

  console.log("makeGenData", rest, fileInput);

  const data = rest;

  let promises = [];

  data.files = fileInput.map(fileInput => {
    const { file, err, ...result} = fileInput;

    promises.push(new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        console.log("read done of " + result.name);
        const content = event.target.result;
        result.content = content;
        resolve();
      });
      reader.addEventListener('error', (event) => {
        console.log("read failed of " + result.name, reader.error);
        reject(reader.error);
      })
      reader.readAsBinaryString(file);
    }));

    return result;
  });

  console.log(promises);

  return Promise.all(promises).then(_ => data);
}

export const makeGenFormData = form => {
  const formData = new FormData();
  Object.keys(form).forEach(key => {
    switch (key) {
      case "namespace": {
        form[key].forEach(ns => {
            formData.append('ns', ns.ns + "\n" + ns.prefix);
        });
        break;
      }
      case "fileInput": {
        form[key].forEach(input => {
            formData.append('input', input.file, input.name);
        });
        break;
      }
      default: {
        formData.append(key, form[key]);
        break;
      }
    }
  });
  return formData;
};
