import React from "react";

import "./DonateButton.scss";
import BemClass from "@upsales/bemclass";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import Text from "components/Text";
import { paypalDonateLink } from "api";

const DonateButton = (props) => {
    const bem = new BemClass("DonateButton");

    const { translate } = props;

    return (
        <a className={bem.b()} target="_empty" href={paypalDonateLink}>
            <Text color="white" bold>
                {translate("donate.with.paypal")}
            </Text>
        </a>
    );
};
const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});
export default connect(mapStateToProps)(DonateButton);
