import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Openable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.setOpen = this.setOpen.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const { history, startOpen } = this.props;
        this.historyListner = history.listen((location, action) => {
            this.close(true);
        });
        
        if (startOpen) {
            this.open();
        }

    }

    setOpen(open) {
        if (open) this.open();
        else this.close();
    }

    open() {
        const { isOpen } = this.state;
        const { history } = this.props;
        if (isOpen) return;

        console.log("Openable", "open()");
        history.push();
        this.setState({ isOpen: true });
    }

    close(fromAction) {
        const { isOpen } = this.state;
        if (!isOpen) return;

        const { history } = this.props;
        console.log("Openable", `close(${fromAction})`);

        if (!fromAction) {
            history.goBack();
        }
        this.setState({ isOpen: false });
    }

    toggle() {
        (this.state.isOpen ? this.close : this.open)();
    }

    render() {
        const { children } = this.props;
        const { isOpen } = this.state;
        return children(isOpen, this.setOpen);
    }
}

export default withRouter(connect()(Openable));
