import AddIcon from '@material-ui/icons/Add';
import BemClass from "@upsales/bemclass";
import ListAdd from "components/Form/ListAdd/ListAdd";
import React, { Component } from "react";
import "./Namespace.scss";
import NS from './NS';


class Namespace extends Component {

  constructor(props) {
    super(props);
    this.newNS = this.newNS.bind(this);
  }

  onChange(index, newValue) {
    const { field, value, onChange } = this.props;
    if (newValue)
      value[index] = newValue;
    else
      value.splice(index, 1);
    onChange(field, value);
  }

  newNS() {
    const { field, value, onChange } = this.props;
    value.push({ns:'', prefix:''});
    onChange(field, value);
  }

  render() {
    const { value, className} = this.props;
    const bem = new BemClass("Namespace", className);
    return (
      <div className={bem.b()}>
        <div className={bem.elem("wrapper").b()}>
          {value ? value.map((v, i) => (<NS key={"ns" + i} value={v} index={i} onChange={(v) => this.onChange(i, v)}></NS>)) : null}
          <ListAdd size='md' onClick={this.newNS} icon={AddIcon}/>
        </div>
      </div>
    );
  }
}
  
export default Namespace;
