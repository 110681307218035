import React from "react";

import "./DonateSuccess.scss";
import BemClass from "@upsales/bemclass";
import Title from "components/Title";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import Text from "components/Text";

const DonateSuccess = (props) => {
    const bem = new BemClass("DonateSuccess");
    const { translate } = props;

    return (
        <div className={bem.b()}>
            <div className={bem.elem("frame").b()}>
                <Title center>{translate("donate.success.title")}</Title>
                <Text center>{translate("donate.success.text")}</Text>
                <Text center>{translate("donate.success.sign")}</Text>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});
export default connect(mapStateToProps)(DonateSuccess);
