import React from "react";

import Overview from "./Overview/Overview";
import Gen from "./Gen/Gen";
import { Switch } from "react-router-dom";
import AppliedRoute from "./AppliedRoute";
import Donate from "./Donate/Donate";
import DonateSuccess from "./Donate/Success/DonateSuccess";
import Resources from "./Resources/Resources";
import Help from "./Help/help";

const RouteSwitch = ({ childProps, children }) => (
  <Switch>
    {Object.values(modules).map(({ path, exact, component, ...rest }) => {
      return (
        <AppliedRoute key={path + "-comp"}
          path={path}
          exact={exact}
          component={component}
          props={{ ...childProps, ...rest }}
        />
      );
    })}
    {children}
  </Switch>
);

export const pathStart = "/";
export const pathGen = "/gen";
export const pathHelp = "/help";
export const pathResources = "/resources";
export const pathDonate = "/donate";
export const pathDonateSuccess = "/donate/thankyou";

export const modules = {
  overview: {
    name: "default.overview",
    path: pathStart,
    meta: 'route.overview.meta',
    exact: true,
    component: Overview,
  },
  resources: {
    name: "default.resources",
    path: pathResources,
    meta: 'route.resources.meta',
    exact: false,
    component: Resources,
  },
  gen: {
    name: "default.gen",
    path: pathGen,
    meta: 'route.gen.meta',
    exact: true,
    component: Gen,
  },
  help: {
    name: "default.help",
    path: pathHelp,
    meta: 'route.help.meta',
    exact: false,
    component: Help,
  },
  donate: {
    name: "default.donate",
    path: pathDonate,
    meta: 'route.donate.meta',
    exact: true,
    component: Donate,
  },
  donateSuccess: {
    name: "default.donateSuccess",
    path: pathDonateSuccess,
    meta: 'route.donateSuccess.meta',
    exact: true,
    component: DonateSuccess,
  },
};

export default RouteSwitch;
