import axios from "axios";
import { API_PATH } from "./index";

export const listDocs = () => {
  return axios.get(`${API_PATH}/listDocs`).then(res => res.data);
};

export const getDoc = (path, cancelToken) => {
  return axios.get(`/help/${path}.md`, null, cancelToken);
};
