import BemClass from "@upsales/bemclass";
import Footer from "components/Footer/Footer";
import Title from "components/Title";
import React from "react";
import About, { Donate, Shttp } from "./About/About";
import "./Overview.scss";
import Runtimes from "./Runtimes/Runtimes";
import Workflow from "./Workflow/Workflow";

export const OverviewTitle = ({ className, children }) => {
    const bem = new BemClass("OverviewTitle", className);

    return (
        <div className={"OverviewTitle"}>
            <div className={bem.elem("divider").b()} />
            <Title size='sm'>{children}</Title>
            <div className={bem.elem("divider").b()} />
        </div>
    );
};

const Overview = () => {
    return (
        <div className='Overview w'>
            {/* <DefGenUti className={compactClassName} /> */}
            <About />
            <Workflow />
            <Runtimes />
            <Shttp />
            <Donate />
            {/* <Who /> */}
            <Footer />
        </div>
    );
};

export default Overview;
