import React, { Component } from "react";
import BemClass from "@upsales/bemclass";
import { Dialog, Drawer, Position } from "@blueprintjs/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "components/IconButton/IconButton";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./Modal.scss";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: window.matchMedia(`(max-width: 599px)`).matches,
    };

    this.onClose = this.onClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps, newState) {
    if (newProps.isOpen && newProps.isOpen !== this.props.isOpen) {
      newProps.history.push();
    }
  }

  componentDidMount() {
    const { history, onClose, isOpen } = this.props;
    window.matchMedia(`(max-width: 599px)`).addListener((e) => {
      this.setState({ mobile: e.matches });
    });

    if (isOpen) {
      history.push();
    }
    this.historyListener = history.listen((location, action) => {
        if (action === "POP") {
        if (onClose) onClose();
      }
    });
  }

  onClose() {
    const { history, isOpen } = this.props;
    if (isOpen) history.goBack();
  }

  componentWillUnmount() {
    if (this.historyListener) this.historyListener();
  }

  render() {
    const {
      className,
      dialog,
      drawer,
      onClose,
      children,
      ...props
    } = this.props;
    const { mobile } = this.state;
    const bem = new BemClass("Modal", className);

    const close = () => {
      this.onClose();
      if (onClose)
        onClose();
    };

    return dialog || (!drawer && !mobile) ? (
      <Dialog className={bem.b()} onClose={close} {...props}>
        {!mobile && (
          <IconButton
            className={bem.elem("close-button").b()}
            borderless
            size='sm'
            icon={CloseIcon}
            onClick={close}
          />
        )}
        {children}
      </Dialog>
    ) : (
      <Drawer
        className={bem.b()}
        onClose={close}
        position={Position.BOTTOM}
        size='60%'
        {...props}
      >
        {!mobile && (
          <IconButton
            className={bem.elem("close-button").b()}
            borderless
            size='sm'
            icon={CloseIcon}
            onClick={close}
          />
        )}
        {children}
      </Drawer>
    );
  }
}

const mapStateToProps = (state, props) => ({});

export default withRouter(connect(mapStateToProps)(Modal));
