import React from "react";
import { connect } from "react-redux";

import "./WsdlTemplateModal.scss";
import { getTranslate } from "react-localize-redux";
import Modal from "components/Modal";
import Title from "components/Title";
import { setValue, resetValue } from "store/itemState";

import Input from "components/Input";

import { inputType } from "helper/genForm";
import { useEffect } from "react";
import BemClass from "@upsales/bemclass";
import Text from "components/Text";
import WseButton from "components/Button/WseButton";

export const parseTarget = (template) => {
    template = {...template};
    if (!template.protocol)
        template.protocol = "http";
    if (!template.host)
        template.host = "temp.uri";
    if (!template.path)
        template.path = "/";
    return template.protocol + "://" + template.host + (template.port ? (":" + template.port) : ('')) + template.path;
}

const {
    text: { templateName, templateHost, templatePort, templateSoapAction, templatePath},
    tabselect: { protocol },
    checkbox: { viewAdvanced }
} = inputType;

const WsdlTemplateModal = (props) => {
    const {translate, isOpen, onClose, template, setValue} = props;

    const template_form = "template";

    useEffect(() => {
        if (!template[viewAdvanced.field]) {
            setValue(template_form, templateSoapAction.field, 'wse:' + template[templateName.field])
            setValue(template_form, templatePath.field, "/" + template[templateName.field])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template[templateName.field], setValue, templateSoapAction.field, templatePath.field]);

    useEffect(() => {
        setValue(template_form, viewAdvanced.field, 0);
    }, [isOpen, setValue])

    const bem = new BemClass("WsdlTemplateModal");

    const target = parseTarget(template);

    return <Modal {...{isOpen, onClose}} className={bem.b()}>
        <Title>{translate('gen.template.title')}</Title>

        <Input {...templateName} form={template_form} />
            <Input {...protocol} form={template_form} />
            <Input {...templateHost} form={template_form} />
            <Input {...templatePort} form={template_form} />

            <Input {...viewAdvanced} form={template_form} />
            <Input {...templateSoapAction} form={template_form} />
            <Input {...templatePath} form={template_form} />
            <Text className={bem.elem('target').b()}>{target}</Text>
            
            <WseButton
                rotate
                className={bem.elem("download").b()}
                onClick={() => {
                    // String type = object.get("type");
                    // String name = object.get("name");
                    // String target = object.get("target");
                    // String SOAPAction = object.get("soapaction");
                    // String namespace = object.get("namespace");

                    const wsdlData = {
                        type: 'wsdl',
                        name: template[templateName.field],
                        target,
                        soapaction: template[templateSoapAction.field],
                        namespace: template[templateSoapAction.field]
                    };

                    const xsdData = {...wsdlData, type: 'xsd'};
                    window.open(`/api/template?data=${btoa(JSON.stringify(wsdlData))}`, "_blank");
                    window.open(`/api/template?data=${btoa(JSON.stringify(xsdData))}`, "_self");

                }}
            >
                {/* <Title></Title> */}
                {translate('gen.template.download')}
            </WseButton>
    </Modal>
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
    template: state.itemState.template
});

const mapDispatchToProps = {
    resetValue,
    setValue,
  };

export default connect(mapStateToProps, mapDispatchToProps)(WsdlTemplateModal);
