import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { Fragment } from 'react';
import './FormCheckbox.scss';


const FormCheckbox = ({ name, value, valid, field, disabled, translate, onChange }) => (
  <FormControlLabel
    value={value}
    label={
      <Fragment>
        {name ? translate(name) : null}
        {/*getRequired(valid) && <span className='input-required'>*</span>*/}
      </Fragment>
    }
    className='FormCheckbox'
    control={
      <Checkbox
        checked={!!value}
        name={field}
        disabled={disabled}
        onChange={onChange}
        disableRipple
      />
    }
  />
);

export default FormCheckbox;
