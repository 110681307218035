import React, { Component } from 'react'
import IconButton from 'components/IconButton/IconButton';
import './ListAdd.scss';

export default class ListAdd extends Component {

    
    render() {
        return <div className={"ListAdd__wrapper"}>
            <IconButton {...this.props}></IconButton>
        </div>
    }
}
