import { gen } from "api";
import { makeGenFormData, parseGenForm, validGenForm } from "helper/parsers";
import { getTranslate } from "react-localize-redux";
import { setResult } from "store/itemState";

export const Generate = () => (dispatch, getState) => {
  const state = getState();
  const translate = getTranslate(state.localize);
  const form = parseGenForm(state.itemState.gen);
  const valid = validGenForm(form);

  if (valid.length > 0) {
    valid.unshift("gen.error.invalidForm");
    dispatch(
      setResult({
        status: -1,
        errlog: valid.filter((v) => v).map((v) => translate(v)),
      })
    );
    return;
  }

  const formData = makeGenFormData(form);

  gen(formData)
    .then((result) => {
      dispatch(setResult(result));
    })
    .catch((e) => {
      console.log(e);

      let result;

      if (e.response) {
        const { status, data, statusText } = e.response;
        result = {
          status: -1,
          errlog: [translate("gen.error.status", { status, statusText }), data],
        };
      } else {
        const { message } = e;
        result = {
          status: -1,
          errlog: [
            translate("gen.error.status", { status: -1, statusText: message }),
            translate("gen.error.networkerrortip"),
          ],
        };
      }
      console.log(result);
      dispatch(setResult(result));
    });
};
