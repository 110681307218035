import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'components/IconButton/IconButton';
import React, { Component } from 'react';
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import err from './err.svg';
import './FileInput.scss';
import wsdl from './wsdl.svg';
import xsd from './xsd.svg';




class File extends Component {

    onChange(f, v) {
        const {value, onChange} = this.props;
        value[f] = v;
        onChange(value);
    }

    render() {
        const {value, key, onChange, translate} = this.props;
        const img = value.err.length ? err : value.name.includes('wsdl') ? wsdl : xsd;
        return (
            <div key={key} className={"File"}>
                <div className="File__icon">
                    <img data-tooltip='Hello' src={img} alt=''></img>
                    {!value.err.length ? null : 
                        <div className="File__err">
                            {value.err.map(msg => <div>{"- " + translate(msg)}</div>)}
                            {<div>{translate('file.err.notIncluded')}</div>}
                        </div>
                    }
                </div>
                <div className={'File__name'}>{value.name}</div>
                <IconButton force borderless size='sm' icon={CloseIcon} onClick={() => onChange(undefined)}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
  });
  
  export default connect(mapStateToProps)(File);
  