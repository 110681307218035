
// ------------------------------------
// Constants
// ------------------------------------
const SET_OVERVIEW = "[DOC STATE] Set overview";
const SET_DOC = "[DOC STATE] Set doc";
const CLEAR_STATE = "[DOC STATE] Clear state";

// ------------------------------------
// Actions
// ------------------------------------
export function setOverview(value) {
  return dispatch => {
    dispatch({
      type: SET_OVERVIEW,
      payload: {
        __overview__: value
      }
    });
  };
}

export function setDoc(field, value) {
  return (dispatch) => {
    dispatch({
      type: SET_DOC,
      payload: {
        [field]: value
      }
    })
  };
}

export function clearState(type) {
  return {
    type: CLEAR_STATE,
    payload: baseState
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_OVERVIEW]: (state, action) => ({...state, ...action.payload}),
  [SET_DOC]: (state, action) => ({ ...state, ...action.payload }),
  [CLEAR_STATE]: (state, action) => action.payload
};

// ------------------------------------
// Reducer
// ------------------------------------
const baseState = {};
export default function docStateReducer(state = baseState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
