import React from "react";
import { listDocs, getDoc } from "api";
import { setOverview, setDoc } from "store/docState";

import showdown from "showdown";
import showdownHighlight from "showdown-highlight";
import parse, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";

function showdownMermaid() {
  return [
    {
      type: "lang",
      regex: /(```mermaid)([\s\S]+?)(```)/g,
      replace: function (whole, left, content, right, location, text) {
        return "<div class='mermaid'>" + content + "</div>";
      },
    },
  ];
}

function showdownFilename() {
  return [
    {
      type: "lang",
      regex: /(`filename)([\s\S]+?)(`)/g,
      replace: function (whole, left, content, right, location, text) {
        return "<div class='filename'>" + content + "</div>";
      },
    },
  ];
}

const converter = new showdown.Converter({
  extensions: [
    showdownMermaid(),
    showdownFilename(),
    showdownHighlight({
      pre: true,
    }),
    // showdownSection()
  ],
});

export const getOverview = () => (dispatch, getState) => {
  listDocs().then((data) => {
    dispatch(setOverview(data));
  });
};

export const getDocs = (path) => (dispatch, getState) => {
  if (getState().docState[path]) return;

  getDoc(path, null)
    .then(parseDoc)
    .then((content) => {
      dispatch(setDoc(path, content));
    });
};

const samePagePrefix = "https://wse.app/";

const parseWithLinks = (text) => {
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "a" && attribs.href) {

        if (attribs.href.startsWith(samePagePrefix)) {
          return (
            <Link to={attribs.href.slice(samePagePrefix.length - 1)}>
              {domToReact(children)}
            </Link>
          );
        }

        if (
          ["https:", "http:", "mailto:", "tel:"].find((v) =>
            attribs.href.startsWith(v)
          )
        )
          return undefined;

        return <Link to={attribs.href}>{domToReact(children)}</Link>;
      }
    },
  };

  return parse(text, options);
};

export const parseDoc = (result) => {
  if ("text/markdown" === result.headers["content-type"]) {
    let htmlContent = converter.makeHtml(result.data);
    htmlContent = parseWithLinks(htmlContent);
    return htmlContent;
  } else {
    return result.data;
  }
};
