import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
// import { getRequired } from 'helpers/validator';
import FormTitle from 'components/Form/FormTitle/FormTitle';
import './Select.scss';
// import InfoIcon from 'components/InfoIcon/InfoIcon';

const propTypes = {
  name: PropTypes.string,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array,
  dynamicOptions: PropTypes.object,
  valid: PropTypes.array,
  className: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export const defaultProps = {
  value: ''
};

export const createDynamicOptions = (
  { from, to, step, nameFn, valueFn, format },
  translate,
  select,
  value
) => {
  const arr = [];
  for (var i = from; i <= to; i = i + step) {
    arr.push(
      <option key={`${select}-dyn-opt-${i}`} value={valueFn ? valueFn(i) : i}>
        {nameFn(translate, format ? format(i) : i)}
      </option>
    );
  }
  return arr;
};

export const getValue = e => {
  if (e.target.value === "ANY") {
    return '';
  }
  return e.target.value;
};

class Select extends React.PureComponent {
  render() {
    const {
      translate,
      name,
      field,
      options,
      dynamicOptions,
      className,
      onChange,
      value,
      disabled,
    } = this.props;
    return (
      <div className={`Inputs Select ${className || ''}`}>
        <FormTitle disabled={disabled}>
          {translate(name)}
        </FormTitle>
        <div>
          <select
            className={'Select__select' + (value === '' ? ' Select__value-default' : '')}
            name={'select-' + name}
            onChange={e => onChange(field, getValue(e))}
            disabled={disabled}
            value={value}
          >
            {options.map(({ name, ...opt }) => (
              <option key={`${name}-${opt.value}`} {...opt}>
                {name(translate)}
              </option>
            ))}
            {dynamicOptions && createDynamicOptions(dynamicOptions, translate, name, value)}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize)
});

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
export default connect(mapStateToProps)(Select);
