import BemClass from "@upsales/bemclass";
import React from "react";
import { connect } from "react-redux";
import "./Logo.scss";
import left_part from "assets/wse_icon_hl.svg";
import right_part from "assets/wse_icon_hr.svg";


const Logo = ({small, medium, large, auto}) => {

    const bem = new BemClass("WseLogo");

    return <div className={bem.mod({small, medium, large, auto}).b()}>
        <img src={left_part} alt=""/>
        <span>Web Service Engine</span>
        <img src={right_part} alt=""/>
    </div>

}

export default connect()(Logo);