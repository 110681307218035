import BemClass from "@upsales/bemclass";
import Text from "components/Text";
import Title from "components/Title";
import WsdlTemplateModal from "components/WsdlTemplateModal/WsdlTemplateModal";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Links.scss";
import "./Resources.scss";
import "./Tutorials.scss";
import { listResources } from "helper/res";
import { resPath } from "api";

const LinksGroup = ({ translate, title, text, children }) => {
  const bem = new BemClass("Links");

  const targetRef = useRef();
  const [span, setSpan] = useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      const height = targetRef.current.offsetHeight;
      setSpan(Math.ceil(height / 10) + 2);
    }
  }, [span]);

  // Use a position of absolute to calculate height, then add it to the grid with correct grid row end
  const style = targetRef.current
    ? {
        gridRowEnd: `span ${span}`,
        // "position": "absolute",
        // "maxWidth": "400px"
      }
    : {
        position: "absolute",
        maxWidth: "400px",
      };

  return (
    <div ref={targetRef} className={bem.elem("group").b()} style={style}>
      <Title size="sm" className={bem.elem("title").b()}>
        {title}
      </Title>
      <Text size="md" className={bem.elem("text").b()}>
        {text}
      </Text>
      {children}
    </div>
  );
};

const LinkOption = ({ onClick, to, href, children, help }) => {
  const bem = new BemClass("Links");
  const content = (
    <div className={bem.elem("option").mod({ help }).b()} onClick={onClick}>
      {children}
    </div>
  );
  if (to) {
    return (
      <Link className={bem.elem("link").b()} to={to}>
        {content}
      </Link>
    );
  } else if (href) {
    return (
      <a className={bem.elem("link").b()} href={href}>
        {content}
      </a>
    );
  } else {
    return content;
  }
};

const Links = (props) => {
  const { translate, listResources, sections } = props;
  const bem = new BemClass("Links");

  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  useEffect(() => {
    listResources();
  }, [listResources]);

  if (sections === undefined) {
    return <div className={bem.b()}></div>;
  }

  return (
    <div className={bem.b()}>
      {sections.map(({ name, description, path: sectionPath, help, files }) => {
        return (
          <LinksGroup key={sectionPath} title={name} text={description}>
            {files
              ? files.map(({ name, path }) => {
                  return (
                    <LinkOption key={path} href={resPath(sectionPath, path)}>
                      {name}
                    </LinkOption>
                  );
                })
              : undefined}

            {help ? (
              <LinkOption help to={help.path}>
                {help.name}
              </LinkOption>
            ) : undefined}
          </LinksGroup>
        );
      })}

      <LinksGroup
        title={translate("res.template.title")}
        text={translate("res.template.desc")}
      >
        <LinkOption onClick={() => setTemplateModalOpen(true)}>
          {translate("res.template.openButton")}
        </LinkOption>
      </LinksGroup>

      <WsdlTemplateModal
        isOpen={templateModalOpen}
        onClose={() => setTemplateModalOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
  sections: state.resState?.sections,
});

const mapDispatchToProps = {
  listResources,
};

export default connect(mapStateToProps, mapDispatchToProps)(Links);
