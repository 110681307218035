import React from 'react';
import PropTypes from 'prop-types';
import './Title.scss';

const sizes = {
  xs: 4,
  sm: 3,
  md: 2,
  lg: 1
};

const colors = ['white', 'green', 'red', 'bright-red'];

const propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)).isRequired,
  color: PropTypes.oneOf(colors),
  center: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  size: 'md',
  text: ''
};

class Title extends React.PureComponent {
  render () {
    const { className, size, noselect, auto, text, bold, color, children, center } = this.props;
    const classes = ['Title'];
    classes.push('Title--' + size);
    if (center) classes.push('Title--center');
    if (auto) classes.push('Title--auto');
    if (bold) classes.push('Title--bold');
    if (color) classes.push('Title--' + color);
    if (noselect) classes.push('noselect');
    if (className) classes.push(className);
    return <div className={classes.join(' ')} role='heading' aria-level={sizes[size]}>{text || children}</div>;
  }
}

Title.sizes = Object.keys(sizes);
Title.propTypes = propTypes;
Title.defaultProps = defaultProps;
export default Title;
