import React, { Component } from 'react'

import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import Text from 'components/Input/Text/Text';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'components/IconButton/IconButton';


class NS extends Component {

    onChange(f, v) {
        const {value, onChange} = this.props;
        value[f] = v;
        onChange(value);
    }

    render() {
        const {value, key, onChange} = this.props;
        return (
            <div key={key} className={"NS"}>
                <Text autoComplete='off' placeholder={'gen.namespace.ns'} className={'NS--namespace'} value={value.ns} onChange={(f, v) => this.onChange('ns', v)}></Text>
                <Text autoComplete='off' placeholder={'gen.namespace.prefix'} className={'NS--prefix'} value={value.prefix} onChange={(f, v) => this.onChange('prefix', v)}></Text>
                <IconButton force borderless size='sm' icon={CloseIcon} onClick={() => onChange(undefined)}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
  });
  
  export default connect(mapStateToProps)(NS);
  