import React from "react";
import "./GenButton.scss";
import { Link } from "react-router-dom";
import BemClass from "@upsales/bemclass";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Generate } from "helper/gen";

class GenButton extends React.Component {
  render() {
    const { path, name, className, onClick, active, translate, Generate } =
      this.props;

    const bem = new BemClass("GenButton", className);

    if (active) {
      return (
        <div
          className={bem.b()}
          id={path}
          onClick={() => {
              if (active)
                Generate();
          }}
        >
          <div>{translate(name)}</div>
        </div>
      );
    }

    

    return (
      <Link
        className={bem.b()}
        id={path}
        to={path}
        onClick={onClick}
      >
        <div>{translate(name)}</div>
      </Link>
    );
  }
}

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = {
  Generate,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenButton);
