import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormTitle from 'components/Form/FormTitle/FormTitle';
import './TextInput.scss';

const sizes = ['sm', 'md'];

const propTypes = {
  translate: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valid: PropTypes.array,
  icon: PropTypes.node,
  disabled: PropTypes.bool
};

const defaultProps = {
  size: 'md',
  className: ''
};

const noOp = () => {};

const inputType = (type, props) => {
  switch (type) {
    case 'textarea':
      return <textarea className='text-input text-area' {...props} />;
    case 'number':
      return <input inputMode='numeric' className='text-input' {...props} />;
    case 'password':
      return <input type='password' className='text-input' {...props} />;
    case 'ns': 
      return <input type='ns' className='text-input' {...props} />;
    case 'text':
    default:
      return <input type='text' className='text-input' {...props} />;
  }
};

const getValue = ({ format, value, template }, translate, focus) => {
  const outValue = transformValue(value, format);
  if (!focus) {
    if (template && (outValue.length || Number.isFinite(outValue))) {
      return translate(template, { value: outValue });
    } else {
      return outValue;
    }
  } else {
    return outValue;
  }
};

export const transformValue = (value, transformFn) => {
  const val = Array.isArray(value) ? value[0] : value;
  if (transformFn) {
    return transformFn(val);
  } else {
    return val;
  }
};

const TextInput = ({
  translate,
  name,
  className,
  type,
  valid,
  placeholder,
  size,
  onChange,
  disabled,
  icon,
  autoComplete,
  autoFocus,
  onPaste,
  maxLength,
  displayMaxLength,
  ...inputProps
}) => {
  const [focus, setFocusState] = useState(false);
  const value = getValue(inputProps, translate, focus);
  const length =
    displayMaxLength && maxLength !== undefined && value !== undefined ? value.length : 0;
  return (
    <div
      className={`TextInput TextInput--${size} ${className || ''}${
        icon ? ' TextInput--hasIcon' : ''
      }${disabled ? ' TextInput--disabled' : ''}`}
    >
      {name && (
        <FormTitle>
          {translate(name)}
          {displayMaxLength && maxLength !== undefined
            ? ` (${translate('default.value.charLeft', { value: maxLength - length })})`
            : ''}
          {/*getRequired(valid) && <span className='input-required'>*</span>*/}
        </FormTitle>
      )}
      {inputType(type, {
        onChange: disabled ? noOp : onChange,
        value,
        placeholder: placeholder ? (autoComplete === 'off' ? '\n' : '') + translate(placeholder) : undefined,
        onFocus: () => setFocusState(true),
        onBlur: () => setFocusState(false),
        disabled,
        autoFocus,
        autoComplete,
        onPaste,
        maxLength
      })}
      {icon}
    </div>
  );
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize)
});

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;
export default connect(mapStateToProps)(TextInput);
