import BemClass from "@upsales/bemclass";
import wse_icon from "assets/wse_icon.svg";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "./HelpTOC.scss";

const File = ({name, path, page}) => {
    const bem = new BemClass("HelpTOC");
    return <div>
        <Link to={`/help/${path}`} className={bem.elem("FileLink")}>
            <div className={bem.elem("File").mod({active: page === path}).b()}>
                {name}
            </div>
        </Link>
    </div>
}

const Folder = ({page, name, files /*, dir */}) => {
    const bem = new BemClass("HelpTOC");
    return <div className={bem.elem("Folder")}>
        <div className={bem.elem("FolderTitle").b()}>
            {name}
        </div>
        {files?.map(f => <File page={page} {...f} />)}
    </div>
}

const HelpTOC = (props) => {
    const isDesktop = useMediaQuery({ minWidth: 800 });
    const bem = new BemClass("HelpTOC");

    const { page, contents } = props;

    return (
        <div className={bem.b()}>
            {isDesktop ? (
                <div className={bem.elem("HeaderFiller").b()} />
            ) : null}
            <div className={bem.elem("Header").mod({ isDesktop }).b()}>
                <Link to="/">
                    <img className={bem.elem("icon").b()} src={wse_icon} alt="wse" />
                </Link>
                <div className={bem.elem("title").b() }>Help Center</div>
            </div>
            <div className={bem.elem("Content").b()}>
                {contents?.files?.map(f => <File page={page} {...f} />)}
                {contents?.dir?.map(f => <Folder page={page} {...f} />)}
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
    path: props.location?.pathname,
    contents: state.docState["__overview__"]
});

export default connect(mapStateToProps)(HelpTOC);
