import BemClass from "@upsales/bemclass";
import Logo from "components/Logo/Logo";
import React from "react";
import "./Footer.scss";

const Footer = () => {
    
    const bem = new BemClass("Footer");

    return (
        <div className={bem.b()}>
            <div className={bem.elem('layout').b()}>
                <Logo small />
            </div>
            <div className={bem.elem('row').b()}>
                <div>{`© 2020 - ${new Date().getFullYear()}, Web Service Engine Sweden AB`}</div>
            </div>
        </div>
    );
};

export default Footer;
