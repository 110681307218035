import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import './FormTitle.scss';

const propTypes = {
  title: PropTypes.node,
  translate: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

const FormTitle = ({ translate, title, className, children, style, disabled }) => {
  const classes = ['FormTitle', className];
  if (disabled) classes.push('FormTitle__disabled');
  return (
    <h3 className={classes.join(' ')} style={style}>
      {title ? translate(title) : children}
    </h3>
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.localize),
  item: state.itemState[props.form]
});

FormTitle.propTypes = propTypes;
export default connect(mapStateToProps)(FormTitle);
