import React from "react";
import "./TopBarButton.scss";
import { Link } from "react-router-dom";
import BemClass from "@upsales/bemclass";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";

class TopBarButton extends React.Component {
    render() {
        const { path, name, className, onClick, active, translate } = this.props;

        const bem = new BemClass("TopBarButton", className);
        return (
            <Link className={bem.mod({ active: active }).b()} id={path} to={path} onClick={onClick}>
                <div >
                    {translate(name)}
                </div>
            </Link>
        );
    }
}

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(TopBarButton);
